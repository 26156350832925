import React from "react";
import Layout from "../layout/Layout";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import brandDefault from "../assets/images/brand-default.png";
import Pagination from "../components/common/Pagination";
import { pagination } from "../utils/common";
import SidebarList from "../components/category/SidebarList";
import Image from "../components/common/Image";
import { sliceAfterMatch } from "../utils/common";
import { brandsStaticURL } from "../ApiEndpoints";

const BrandsPageTemplate = ({ location, brands, pageContext }) => {
  const { currentPage, totalPages } = pageContext;
  return (
    <div className="page-wrapper">
      <div className="container">
        <Breadcrumbs location={location} />
      </div>
      <div className="page-heading-section">
        <div className="container">
          <h2 className="page-title">{"Brands"}</h2>
        </div>
      </div>

      <div className="container">
        <div className="row flex flex-wrap page-layout">
          <div className="col page-sidebar page-sidebar-toggle">
            <div className="page-sidebar-inner-wrap">
              <SidebarList />
            </div>
          </div>

          <div className="col page-content">
            <div className="page-layout">
              <ul className="brands-list">
                {brands.map((brand) => (
                  <li key={brand.id}>
                    <Link to={brand?.url}>
                      <div className="brand-figure">
                        {brand.imageUrl && brand?.imageUrl.includes(
                          "https://cdn11.bigcommerce.com"
                        ) ? (
                          <Image
                            src={brand.imageUrl || brandDefault}
                            alt={brand.imageUrl}
                          />
                        ) : (
                          <Image
                            src={
                              brand?.imageUrl && brand?.imageUrl.includes("/brands/images")
                                ? `${
                                    process.env.GATSBY_IMAGE_CDN_BASEURL
                                  }${brandsStaticURL}${sliceAfterMatch(
                                    brand?.imageUrl,
                                    "/brands/images"
                                  )}`
                                : brandDefault
                            }
                            alt="header logo"
                          />
                        )}
                      </div>
                      <p>{brand.name}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalPages}
              onPageChange={(page) => pagination(page, "brands")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BrandsPageTemplate.propTypes = {
  brands: PropTypes.array,
};

function BrandsPage({ data, location, pageContext }) {
  const allBrands = data.allDynamicBrands.edges.map((item) => {
    const { id, url, name, image_url: imageUrl } = item.node;

    if (url) {
      return {
        id,
        url: url,
        name,
        imageUrl,
      };
    } else {
      return null;
    }
  });

  return (
    <BrandsPageTemplate
      location={location}
      brands={allBrands}
      pageContext={pageContext}
    />
  );
}

BrandsPage.propTypes = {
  data: PropTypes.shape({
    allDynamicBrands: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.object,
};

BrandsPage.Layout = Layout;
export default BrandsPage;

export const brandsPageQuery = graphql`
  query GetAllBrands($skip: Int!, $limit: Int!) {
    allDynamicBrands(
      limit: $limit
      skip: $skip
      filter: { id: { ne: "333" } }
    ) {
      edges {
        node {
          id
          name
          url
          image_url
        }
      }
    }
  }
`;
